.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  font-size: calc(8px + 2vmin);
  color: #be1e2d;
  text-shadow: rgb(255, 255, 255) 1px 1px 7px;
  background: rgb(194,194,194);
}

.Sub-Text {
  font-size: calc(5px + 2vmin);
}


h2 {
  margin: 0px;
}

p {
  margin: 0px;
}

